<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow w-100">
      <!-- title above form card -->
      <h1 class="px-4 max-100">Dubbelglas</h1>
      <h6 class="px-4 pb-3 max-100 text-primary fade-in-up-enter-active animation-slowest delay-200">Kozijnen</h6>

      <!-- form 1 card -->
      <base-card class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="radiobuttons" :options="frameMaterialOptions"
            name="FrameMaterial"
            v-model:value="lead.glazing.frame.FrameMaterial" :validator="v$.glazing.frame.FrameMaterial">
            Van welk materiaal zijn de kozijnen?
          </base-input>
          <issue-feedback :issue="qualStore.sections.glazing.rules.FrameMaterial" />

          <!-- inputs -->
          <transition name="fade-in-up">
            <base-input
              v-show="lead.glazing.frame.FrameMaterial == 'hout'"
              type="radiobuttons" :options="frameConditionOptions"
              name="FrameCondition"
              v-model:value="lead.glazing.frame.FrameCondition" :validator="v$.glazing.frame.FrameCondition">
              Wat is de staat van het houtwerk?
            </base-input>
          </transition>
          <issue-feedback :issue="qualStore.sections.glazing.rules.FrameCondition" />
          
          <!-- inputs -->
          <transition name="fade-in-up">
            <base-input
              v-show="lead.glazing.frame.FrameMaterial"
              type="radiobuttons" :options="rebateWidthOptions"
              name="RebateWidthSufficient"
              v-model:value="lead.glazing.frame.RebateWidthSufficient" :validator="v$.glazing.frame.RebateWidthSufficient">
              Wat is de sponningbreedte van het kozijn?
            </base-input>
          </transition>
          <issue-feedback :issue="qualStore.sections.glazing.rules.RebateWidthSufficient" />

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.glazing.frame.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          { label: 'Ja', value: true },
          { label: 'Nee', value: false },
        ],
        frameMaterialOptions: [
          { label: 'Hout', value: 'hout' },
          { label: 'Aluminium', value: 'aluminium' },
          { label: 'Kunststof', value: 'kunststof' },
        ],
        frameConditionOptions: [
          { label: 'Goed', value: 'good' },
          { label: 'Slecht', value: 'bad' },
        ],
        rebateWidthOptions: [
          { label: '< minder dan 40mm', value: false },
          { label: '40mm of meer >', value: true },
        ],
      };
    },
    computed: {
      glazingAgeOptions() {
        let type = this.lead.glazing.frame.CurrentGlazingType;
        if (type == 'enkel') {
          return [
            { label: 'Vóór <span class="glazing-year-display">< 1960</span>', value: 'voor-1960' },
            { label: '<span class="glazing-year-display">> 1960</span> of later', value: 'na-1960' },
          ];
        } else if (type == 'dubbel') {
          return [
            { label: 'Vóór <span class="glazing-year-display">< 1995</span>', value: 'voor-1995' },
            { label: '<span class="glazing-year-display">> 1995</span> of later', value: 'na-1995' },
          ];
        }
        return [];
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.glazing.frame.$touch();
        return !this.v$.glazing.frame.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
  .glazing-year-display {
    font-weight: 600;
    border: 1px solid $white;
    display: inline-block;
    padding: 5px 10px;
    border-radius: $border-radius;
    letter-spacing: 0.7px;
  }
</style>