export default function(classes = {}) {
  let cl = {
    classes: {},
    addClass(newClasses) {
      if (!newClasses) { return this }
      // if string, add single new class
      if (typeof newClasses === 'string') {
        this.classes[newClasses] = true
      // if array, add property for each class in array
      } else if (Array.isArray(newClasses)) {
        newClasses.forEach(className => {
          this.classes[className] = true
        });
      // else assume it is an object
      } else {
        this.classes = {...this.classes, ...newClasses}
      }
      return this
    },
    getAll() {
      return this.classes;
    }
  }
  cl.addClass(classes)
  return cl
}
