<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100">Eigendomsinformatie</h1>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-meager w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="yesNo"
              name="PropertyOwner"
              v-model:value="lead.ownership.PropertyOwner" :validator="v$.ownership.PropertyOwner" :do-not-reward="true">
              Bent u de eigenaar van de woning?
            </base-input>
            
            <base-input
              type="radiobuttons" :options="yesNo"
              name="HoaMember"
              v-model:value="lead.ownership.HoaMember" :validator="v$.ownership.HoaMember" :do-not-reward="true">
              Bent u onderdeel van een VVE?
            </base-input>

            <Transition name="fade-in-up">
              <base-input
                v-show="lead.ownership.HoaMember == true"
                type="radiobuttons" :options="yesNo" name="HoaPermission"
                v-model:value="lead.ownership.HoaPermission" :validator="v$.ownership.HoaPermission" :do-not-reward="true">
                Heeft u toestemming van de VVE?
              </base-input>
            </Transition>
          </base-form>
        <!-- form card end -->
        </base-card>

        <!-- form 2 card -->
        <Transition name="fade-in-up">
          <base-card class="pt-2" inner-class="container-meager fade-in-up-enter-active animation-slowest" color="alternate" :outer-padding="true" v-show="showHoaContactForm">
            <base-form @submit="onSubmit">
              <div class="section-hoa-contact pt-3">

                <h5>Contactgegevens vertegenwoordiger VVE</h5>

                <base-input name="HoaRepName"
                  v-model:value="lead.ownership.HoaRepName" :validator="v$.ownership.HoaRepName">
                  Volledige naam
                </base-input>

                <base-input name="HoaRepPhone" placeholder="+316 00 00 00 00"
                  v-model:value="lead.ownership.HoaRepPhone" type="phone" :validator="v$.ownership.HoaRepPhone">
                  Telefoonnummer
                </base-input>

                <base-input name="HoaRepEmail" placeholder="test@example.com" 
                  v-model:value="lead.ownership.HoaRepEmail" :validator="v$.ownership.HoaRepEmail" :debounce="700" :debounce-touch-only="true">
                  E-mailadres
                </base-input>

              </div>
            </base-form>
          </base-card>
        </Transition>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.ownership.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ]
      };
    },
    computed: {
      showHoaContactForm() {
        return (this.lead.ownership.HoaPermission == true && this.lead.ownership.HoaMember == true);
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>