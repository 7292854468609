<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100">Warmtepomp</h1>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-meager w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="heatingSystemOptions"
              inputClass="fs-0-9em fw-normal"
              name="HeatingSystem" direction="column"
              text-align="left"
              v-model:value="lead.heatpump.HeatingSystem" :validator="v$.heatpump.HeatingSystem">
              Welk warmteafgiftesysteem heeft uw woning?
            </base-input>

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="financingOptions"
              inputClass="fs-0-9em fw-normal"
              name="HeatpumpFinancing" direction="column"
              text-align="left"
              v-model:value="lead.heatpump.HeatpumpFinancing" :validator="v$.heatpump.HeatpumpFinancing">
              Hoe wilt u de investering financieren?
            </base-input>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.heatpump.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        heatingSystemOptions: [
          {
            'label': 'Radiatoren',
            'value': 'Radiatoren',
            'icon': 'bi-radiator'
          },
          {
            'label': 'Radiatoren en vloerverwarming',
            'value': 'Radiatoren en vloerverwarming',
            'icon': 'bi-radiator-with-underfloor'
          },
          {
            'label': 'Vloerverwarming (watergedragen)',
            'value': 'Vloerverwarming',
            'icon': 'bi-underfloor-heating'
          },
          {
            'label': 'LTV radiatoren',
            'value': 'LTV radiatoren',
            'icon': 'bi-ltv-radiator-full'
          },
          {
            'label': 'Luchtverwarming',
            'value': 'Luchtverwarming',
            'icon': 'bi-wind'
          },
          {
            'label': 'Elektrisch',
            'value': 'Elektrisch',
            'icon': 'bi-plug-fill'
          }
        ],
        financingOptions: [
          {
            label: 'Eigen middelen',
            value: 'Eigen middelen'
          },
          {
            label: 'Lening via Warmtefonds',
            value: 'Warmtefonds'
          },
          {
            label: 'Energiebespaarlening',
            value: 'Energiebespaarlening'
          },
          {
            label: 'Hypotheek',
            value: 'Hypotheek'
          },
          {
            label: 'Weet ik nog niet',
            value: 'Weet ik nog niet'
          }
        ]
      };
    },
    computed: {
    },
    methods: {
      touchAndValidate() {
        this.v$.heatpump.$touch();
        return !this.v$.heatpump.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>