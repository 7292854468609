import { StripQuery as StripQueryFunction } from "./StripQuery";

/* eslint-disable */
export const CommonComposable = function(props, context) {
/* eslint-enable */

  const StripQuery = StripQueryFunction;

  return { StripQuery };

}