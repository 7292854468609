export default function(value = null, arrayToSearch, caseSensitive = false) {

  // if not array is not array, or if value is falsy
  if (!Array.isArray(arrayToSearch) || value == null) {
    console.warn('ArrayIncludesCaseInsensitive: invalid arguments', value, arrayToSearch);
    return false;
  }

  for (const currValue of arrayToSearch) {
    if (!caseSensitive) {
      if (currValue.toLowerCase() == value.toLowerCase()) {
        return true;
      }
    }
    if (currValue == value) {
      return true;
    }
  }

  return false;

}