<template>
  <router-view v-slot="{ Component }">
      <transition name="slide-fade" mode="out-in">
          <component :is="Component" />
      </transition>
  </router-view>
</template>

<style lang="scss">
    // slide-fade animation
    #app {
        overflow: hidden;
    }

    .slide-fade-enter-active {
        transition: all 0.2s ease-out;
    }
    .slide-fade-leave-active {
        transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from {
        transform: translateX(20px);
        opacity: 0;
    }

    .slide-fade-leave-to {
        transform: translateX(-20px);
        opacity: 0;
    }
</style>