<template>
  <form @submit="onSubmit">
    <slot></slot>
    <button v-if="hiddenSubmitBtn" type="submit" style="display: none"></button>
  </form>
</template>

<script>
  export default {
    props: {
      // optional to render hidden submit button
      // this allows users to trigger submit using enter key
      hiddenSubmitBtn: {
        type: Boolean,
        default: true
      },
    },
    methods: {
      onSubmit(event) {
        event.preventDefault();
        this.$emit('submit');
        return false;
      }
    }
  }
</script>