<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest text-primary max-100">Maatregelen</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Voor welke maatregelen zou u graag in aanmerking willen komen?</h5>

      <!-- form 1 card -->
      <base-card inner-class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <button-grid-input
            name="Interests"
            :max-cols="maxCols"
            :btn-direction="btnDirection"
            type="checkbox"
            btn-class="min-40"
            :pairs-mode="true"
            :distribute-equally="true"
            :options="interestOptions"
            variant="minimal-filled-info"
            inactiveVariant="minimal-dark"
            v-model:value="lead.interests.Interests">
          Kies de maatregelen waarin u interesse heeft:
          </button-grid-input>

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.interests.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  import ButtonGridInput from "@/components/ButtonGridInput.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"
  import { getInterestDefinitions } from "@/rules/InterestDefinitions"

  export default {  
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseButton,
      ButtonRow,
      ButtonGridInput
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        maxCols: null,
        btnDirection: 'column'
      };
    },
    computed: {
      interestOptions: function() {
        return getInterestDefinitions(this.lead.meta.AllInterestsEnabled);
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.interests.$touch();
        return !this.v$.interests.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      },
      breakPoints() {
        if (window.innerWidth > 550) {
          this.maxCols = null;
          this.btnDirection = 'column';
        } else {
          this.maxCols = 1;
          this.btnDirection = 'row';
        }
      }
    },
    beforeRouteUpdate() {
      this.breakPoints();
    },
    mounted() {
      this.breakPoints();
      window.addEventListener("resize", this.breakPoints);
    },
    unmounted() {
      window.removeEventListener("resize", this.breakPoints);
    }
  }
</script>

<style lang="scss">
</style>