import { computed } from "vue";
import { QualIssue } from '@/services/QualClasses';
import { useFormStores as useStores } from '@/rules/_useFormStores';

export const rules = {
  // check if enabled and complete
  enabledAndIncomplete: computed(() => {
    let { lead, validator } = useStores('roof', true);
    // check if interest is checked
    if (!lead.interestsArray.includes('Roof')) {
      return null;
    }
    // if checked, check if form is valid
    if (validator.$invalid) {
      return new QualIssue({
        view: 'formRoof',
        title: 'Belangrijke informatie ontbreekt',
        description:
          'Niet alle vereiste informatie is ingevuld. Daarom kunnen wij (nog) niet garanderen dat u in aanmerking komt voor dakisolatie.',
      });
    }
    return null;
  }),

  // insulating roof that is already insulated is difficult
  RoofInsulationStatus: computed(() => {
    let { form } = useStores('roof');
    if (form.general.RoofInsulationStatus == 'Volledig geisoleerd') {
      return new QualIssue({
        view: 'formRoof',
        title: 'Uw dak is reeds ge&iuml;soleerd',
        description:
          'Bij een reeds geïsoleerd dak is er een grote verbouwing nodig om opnieuw te isoleren.',
      });
    }
    return null;
  }),

  // roof side must be inner
  RoofInsulationSide: computed(() => {
    let { form } = useStores('roof');
    if (form.general.RoofInsulationSide == 'outside') {
      return new QualIssue({
        view: 'formRoof',
        title: 'U wilt het dak van buitenaf isoleren',
        description: 'Wij isoleren uit praktische overwegingen en hoge kosten op dit moment alleen daken van binnenuit.',
      });
    }
    return null;
  }),

  // roof must not be flat
  RoofType: computed(() => {
    let { form } = useStores('roof');
    if (form.general.RoofType == 'Plat dak') {
      return new QualIssue({
        view: 'formRoof',
        title: 'U heeft een plat dak',
        description: 'Wij isoleren helaas geen platte daken vanwege het risico op vochtproblemen.',
      });
    }
    return null;
  }),

  // roof must be accessible
  AccessToRoofInside: computed(() => {
    let { form } = useStores('roof');
    if (form.attic.AccessToRoofInside == 'Niet toegankelijk') {
      return new QualIssue({
        view: 'formRoofAttic',
        title: 'Binnenkant dak niet bereikbaar',
        description:
          'Het is noodzakelijk dat wij de binnen van het dak kunnen bereiken om te isoleren.',
      });
    }
    return null;
  }),

  // when used as living area, and finishing present, finishing must be removed
  RoofInsideFinishingPresent: computed(() => {
    let { form } = useStores('roof');
    if (
      form.attic.AtticUsage.includes('living') &&
      form.finishing.RoofInsideFinishingPresent == true
    ) {
      return new QualIssue({
        view: 'formRoofFinishing',
        type: 'warning',
        title: 'U dient de afwerking te verwijderen',
        description:
          'Afwerking moet worden gesloopt en verwijderd vóór aanvang van de werkzaamheden.',
      });
    }
    return null;
  }),

  // when used as living area, and finishing present, finishing must be removed
  RoofFinishingRemovalPermission: computed(() => {
    let { form } = useStores('roof');
    if (
      form.finishing.RoofInsideFinishingPresent == true &&
      form.finishing.RoofFinishingRemovalPermission == false
    ) {
      return new QualIssue({
        view: 'formRoofFinishing',
        title: 'Verwijderen van afwerking is noodzakelijk',
        description:
          'Indien de afwerking niet verwijderd kan worden is dakisolatie niet mogelijk.',
      });
    }
    return null;
  }),

  // when used as living area, and finishing present, finishing must be removed
  RoofBeamCoveringRemoved: computed(() => {
    let { form } = useStores('roof');
    if (
      form.attic.AtticUsage.includes('living') &&
      form.finishing.RoofInsideFinishingPresent == true &&
      form.finishing.RoofBeamCoveringRemoved == false
    ) {
      return new QualIssue({
        view: 'formRoofFinishing',
        type: 'warning',
        title: 'Let op: meerkosten voor demontage',
        description:
          'U heeft aangegeven de afwerking niet zelf te gaan verwijderen. De uitvoerder zal de afwerking moeten demonteren en er zullen dus extra kosten ontstaan. U accepteert deze meerkosten.',
      });
    }
    return null;
  }),
};
