<template>
  <div :class="interviewWrapperClasses">
    <Transition name="fade-in">
      <interview-nav v-show="showNav" :touch-and-validate="touchAndValidate" />
    </Transition>
    <autosave-bar />
    <div :class="mainContentClasses">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
  import InterviewNav from '@/components/InterviewNav.vue';
  import AutosaveBar from '@/components/AutosaveBar.vue';

  export default {
    components: {
      InterviewNav,
      AutosaveBar
    },
    props: {
      // Add default padding to the main content (default) slot.
      // Set to false when you want to handle sidebar padding in the slot content.
      paddingMainY: {
        type: Boolean,
        default: true
      },
      paddingMainX: {
        type: Boolean,
        default: true
      },
      itemsCenter: {
        type: Boolean,
        default: true
      },
      showNav: {
        type: Boolean,
        default: true
      },
      standardBackground: {
        type: Boolean,
        default: true
      },
      touchAndValidate: {
        type: Function,
        default: null
      }
    },
    computed: {
      interviewWrapperClasses() {
        return {
          'interview-wrapper': true,
          'wrapper-bg-std': this.standardBackground
        }
      },
      mainContentClasses() {
        return {
          'main-content': true,
          'main-content-padding-y': this.paddingMainY,
          'main-content-padding-x': this.paddingMainX,
          'main-content-items-center': this.itemsCenter
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .interview-wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    &.wrapper-bg-std {
      background-color: $std-background;
    }

    .main-content {
      display: flex;
      flex: 1;
      justify-content: center;
      &.main-content-items-center {
        align-items: center;
      }
            
      &.main-content-padding-y {
        padding-top: map-get($spacers, 5);
        padding-bottom: map-get($spacers, 5);
      }
      &.main-content-padding-x {
        padding-left: map-get($spacers, 5);
        padding-right: map-get($spacers, 5);

        @include media-breakpoint-down(md) {
          padding-left: map-get($spacers, 2);
          padding-right: map-get($spacers, 2);
        }
      }
    }
  }
</style>