<template>
  <button
    v-if="tag == 'button'"
    :type="type"
    :disabled="disabled"
    :value="value"
    :class="getButtonClass"
    :tabindex="tabindex"
    @click="$emit('click', $event)"
  >
    <i v-if="icon && iconPosition == 'start'" :class="getIconClass"></i>
    <slot></slot>
    <i v-if="icon && iconPosition == 'end'" :class="getIconClass"></i>
  </button>

  <a v-else-if="tag == 'a'"
    :href="href"
    :target="target"
    :class="getAnchorClass"
    :tabindex="tabindex"
    @click="$emit('click', $event)"
  >
    <i v-if="icon && iconPosition == 'start'" :class="getIconClass"></i>
    <slot></slot>
    <i v-if="icon && iconPosition == 'end'" :class="getIconClass"></i>
  </a>

  <component
    v-else
    :is="tag"
    role="button"
    :disabled="disabled"
    :value="value"
    :class="getDivClass"
    :tabindex="tabindex"
    @click="$emit('click')"
  >
    <i v-if="icon && iconPosition == 'start'" :class="getIconClass"></i>
    <slot></slot>
    <i v-if="icon && iconPosition == 'end'" :class="getIconClass"></i>
  </component>
</template>

<script>
  import ClassList from "@/helpers/ClassList";

  export default {
    emits: ['click'],
    props: {
      // choose between <button> and <a> tag other tags are rendered using :is
      tag: {
        type: String,
        default: 'button'
      },
      // type attribute for button element
      type: {
        type: String,
        default: 'button'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      // toggles between variant and activeVariant
      active: {
        type: Boolean,
        default: false
      },
      // button value attribute - omitted when false
      value: {
        default: false
      },
      // href for <a> element
      href: {
        type: String,
        default: '#'
      },
      // target for <a> element
      target: {
        type: String,
        default: '_self'
      },
      // tabindex value
      tabindex: {
        type: Number,
        default: 0
      },
      // class for button variant
      variant: {
        type: String,
        default: 'primary'
      },
      // class for button variant when active == true
      activeVariant: {
        type: String,
        default: (props) => {
          return 'outline-'+props.variant;
        }
      },
      // alternative to default disabled styling: explicit variant for disabled state
      disabledVariant: {
        type: String,
        default: null
      },
      rounded: {
        type: Boolean,
        default: false
      },
      // extra classes for button or a element
      class: {
        type: [String, Array, Object]
      },
      // bootstrap-icons icon classname
      icon: {
          type: String,
          default: null
      },
      // position for icon (start or end)
      iconPosition: {
        type: String,
        default: 'end',
        validator(value) {
          return ['start', 'end'].includes(value)
        }
      },
      // additional classes for icon
      iconClass: {
        type: [String, Object]
      },
    },
    computed: {
      getButtonClass() {
        let cl = ClassList({
            'btn': true,
            'disabled': this.disabled,
            'no-disabled-style': this.disabledVariant,
            'btn-rounded': this.rounded
          });
          if (this.disabled && this.disabledVariant) {
            cl.addClass('btn-' + this.disabledVariant);
          } else if (this.active) {
            cl.addClass('btn-variant-active');
            cl.addClass('btn-' + this.activeVariant);
          } else {
            cl.addClass('btn-' + this.variant);
          }
          cl.addClass(this.class);
        return cl.getAll();
      },
      getAnchorClass() {
        return this.getButtonClass
      },
      getDivClass() {
        return this.getButtonClass
      },
      getIconClass() {
        return ClassList({
          'bi': true,
          'ms-2': this.iconPosition == 'end',
          'me-2': this.iconPosition == 'start'
        })
        .addClass(this.icon)
        .addClass(this.iconClass)
        .getAll()
      }
    }
  }
</script>

<style lang="scss">
  .btn {
    font-weight: 500;
    font-size: 1em;
    padding: map-get($spacers, 2) map-get($spacers, 3);
    transition: border 0.3s ease;
    &.btn-rounded {
      border-radius: 99px;
    }

    &.no-disabled-style {
      &:disabled, &.disabled {
        opacity: 1;
      }
    }

    &:focus {
      border-style: dashed;
    }

    &.btn-lg {
      font-size: 1em;
    }

    @each $color, $value in $theme-colors {

      $local-gradient-s: lighten($value, 2%);
      $local-gradient-e: darken($value, 2%);
      $local-box-shadow: darken($value,5%);
      $local-hover-gradient-s: lighten($value, 0%);
      $local-hover-gradient-e: darken($value, 5%);
      $local-disabled-gradient-s: desaturate($local-gradient-s, 25%);
      $local-disabled-gradient-e: desaturate($local-gradient-e, 25%);

      &.btn-#{$color} {
        background-color: $value;
        background: linear-gradient(110deg, $local-gradient-s, $local-gradient-e);
        box-shadow: 0 5px 20px rgba($local-box-shadow, 0.4);

        &:hover {
          background-color: darken($value, 10%);
          background: linear-gradient(110deg, $local-hover-gradient-s, $local-hover-gradient-e);
        }

        &:not(.no-disabled-style) {
          &:disabled, &.disabled {
            background-color: $value;
            background: linear-gradient(110deg, $local-disabled-gradient-s, $local-disabled-gradient-e);
            border: $local-disabled-gradient-e;
            opacity: 0.5;
          }
        }
      }

    }
  }
</style>

<style scoped>
i {
  display: inline-block;
}
i::before {
  transition: all 0.3s ease;
}
</style>