<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow w-100">
      <!-- title above form card -->
      <h1 class="px-4 max-100">Dakisolatie</h1>
      <h6 class="px-4 pb-3 max-100 text-primary fade-in-up-enter-active animation-slowest delay-200">Ruimte onder het dak</h6>

      <!-- form 1 card -->
      <base-card inner-class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="radiobuttons" :options="yesNo"
            name="RoofInsulationStatus"
            v-model:value="lead.roof.attic.AtticPresent" :validator="v$.roof.attic.AtticPresent">
            Heeft de woning een zolder?
          </base-input>

          <!-- inputs -->
          <base-input
            type="buttongrid" :options="accessInsideRoofOptions"
            button-grid-input-type="radio"
            button-direction="column"
            :show-input="false"
            :min-col-width="200"
            name="AccessToRoofInside"
            v-model:value="lead.roof.attic.AccessToRoofInside" :validator="v$.roof.attic.AccessToRoofInside">
            Hoe is de binnenzijde van het dak te bereiken?
          </base-input>
          <issue-feedback :issue="qualStore.sections.roof.rules.AccessToRoofInside" />
          
          <!-- inputs -->
          <transition name="fade-in-up">
            <base-input
              v-show="!lead.skipRoofFinishing && !v$.roof.attic.AccessToRoofInside.$invalid"
              type="buttongrid" :options="atticUsageOptions"
              button-direction="column"
              :min-col-width="200"
              name="AtticUsage"
              v-model:value="lead.roof.attic.AtticUsage" :validator="v$.roof.attic.AtticUsage">
              Hoe wordt de ruimte onder het dak gebruikt?
            </base-input>
          </transition>

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.roof.attic.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores";
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        accessInsideRoofOptions: [
          {
            label: 'Vaste trap',
            value: 'Trap',
            icon: 'bi-staircase'
          },
          {
            label: 'Deels vaste trap, deels vlizotrap',
            value: 'Deels vaste trap / deels vlizotrap',
            icon: 'bi-stair-ladder-hybrid',
            labelStyle: 'font-size: 0.9em;'
          },
          { 
            label: 'Via vlizotrap',
            value: 'Vlizotrap',
            icon: 'bi-ladder'
          },
          {
            label: 'Niet toegankelijk',
            value: 'Niet toegankelijk',
            icon: 'bi-slash-circle',
            iconStyle: 'font-size: 25px;',
            labelStyle: 'font-size: 0.9em;'
          },
        ],
        atticUsageOptions: [
          {
            label: 'Woonruimte',
            value: 'living',
            icon: 'bi-lamp'
          },
          {
            label: 'Opslag',
            value: 'storage',
            icon: 'bi-box-seam',
            iconStyle: 'font-size: 27px;'
          }
        ]
      };
    },
    methods: {
      touchAndValidate() {
        this.v$.roof.attic.$touch();
        return !this.v$.roof.attic.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>