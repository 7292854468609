import { computed } from "vue";
import { QualIssue } from '@/services/QualClasses';
import { useFormStores as useStores } from '@/rules/_useFormStores';

export const rules = {
  // check if enabled and complete
  enabledAndIncomplete: computed(() => {
    let { lead, validator } = useStores('solar', true);
    // check if interest is checked
    if (!lead.interestsArray.includes('Solar')) {
      return null;
    }
    // if checked, check if form is valid
    if (validator.$invalid) {
      return new QualIssue({
        view: 'formSolar',
        title: 'Belangrijke informatie ontbreekt',
        description:
          'Niet alle vereiste informatie is ingevuld. Daarom kunnen wij (nog) niet bepalen of u in aanmerking komt voor zonnepanelen.',
      });
    }
    return null;
  }),

  PlacementLocations: computed(() => {
    let { form } = useStores('solar');
    if (form.PlacementLocations.includes('Geen ruimte')) {
      return new QualIssue({
        view: 'formSolar',
        title: 'Geen ruimte voor zonnepanelen',
        description:
          'U heeft aangegeven geen ruimte te hebben voor zonnepanelen',
      });
    }
    return null;
  })

};
