<template>
  <div class="pane-row">
    <div class="pane-row__dimension-col">
      <base-input
        name="height"
        type="number"
        min="0"
        :value="getPane.height"
        @update:value="updatePaneField('height', $event)"
      >
        <template #default>
          Hoogte
        </template>
        <template #after>
          <span class="unit px-2 small fw-600 text-secondary">cm</span>
        </template>
      </base-input>
    </div>
    <div class="pane-row__dimension-col">
      <base-input
          name="width"
          type="number"
          min="0"
          :value="getPane.width"
          @update:value="updatePaneField('width', $event)"
        >
          <template #default>
            Breedte
          </template>
          <template #after>
            <span class="unit px-2 small fw-600 text-secondary">cm</span>
          </template>
        </base-input>
      </div>
      <div class="pane-row__delete-btn align-self-end my-3">
          <base-button variant="outline-danger" @click="deleteRow()" v-show="deleteEnabled">
            <i class="bi bi-trash"></i>
          </base-button>
      </div>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput.vue"
import BaseButton from "@/components/BaseButton.vue"

export default {
  components: {
    BaseInput,
    BaseButton
  },
  props: {
    // rowLabel: {
    //   type: [String, Number]
    // },
    pane: {
      type: Object
    },
    deleteEnabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getPane: function() {
      var defaultPane = {
        height: null,
        width: null
      }
      return Object.assign(defaultPane, this.pane);
    }
  },
  methods: {
    updatePaneField: function(field, value) {
      let pane = this.getPane;
      pane[field] = value;
      this.$emit('update:pane', pane);
    },
    deleteRow: function() {
      this.$emit('delete');
    }
  }
}
</script>

<style scoped lang="scss">
.pane-row {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0,0,0,0.05);
  border-radius: 4px;
  gap: 20px;
  padding: 0px 20px;

  &__dimension-col {
    width: 150px;
  }
}
</style>