<template>
  <div :class="rowClass">
    <slot></slot>
  </div>
</template>

<script>
import ClassList from "@/helpers/ClassList"

export default {
  props: {
    // additional classes
    class: {
      type: [String, Array, Object]
    }
  },
  computed: {
    rowClass() {
      return ClassList('btn-row')
        .addClass(this.class)
        .getAll()
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>