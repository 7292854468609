import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
import { globalVuelidatePlugin } from './store/LeadValidator';

// import compiled base styles scss and bootstrap-icons
import '@/assets/css/style.css';
import '@/assets/css/bs-icons/bootstrap-icons.css';

createApp(App)
  .use(createPinia())
  .use(globalVuelidatePlugin)
  .use(router)
  .mount('#app');
