import { useInterfaceStore } from '@/store/UiStore';
import { useLeadStore } from '@/store/LeadStore';
import { useGlobalVuelidate } from '@/store/LeadValidator';
import { useQualificationStore } from '@/store/QualificationStore';

export const useAllStores = () => {
  const lead = useLeadStore()
  const v$ = useGlobalVuelidate()
  const uiStore = useInterfaceStore()
  const qualStore = useQualificationStore()
  return { lead, v$, uiStore, qualStore };
}