<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow w-100">
      <!-- title above form card -->
      <h1 class="px-4 max-100">Dakisolatie</h1>
      <h6 class="px-4 pb-3 max-100 text-primary fade-in-up-enter-active animation-slowest delay-200">Afwerking binnenzijde</h6>

      <!-- form 1 card -->
      <base-card class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="radiobuttons" :options="yesNo"
            name="RoofBeamsVisible"
            v-model:value="lead.roof.finishing.RoofBeamsVisible" :validator="v$.roof.finishing.RoofBeamsVisible">
            Ziet u de balken/dakbeschot op het te isoleren deel van het dak?
          </base-input>

          <!-- inputs -->
          <base-input
            v-show="show_RoofInsideFinishingPresent"
            type="radiobuttons" :options="yesNo"
            name="RoofInsideFinishingPresent"
            v-model:value="lead.roof.finishing.RoofInsideFinishingPresent" :validator="v$.roof.finishing.RoofInsideFinishingPresent">
            Is er een afwerking aan de binnenzijde van het dak?
          </base-input>
          
          <!-- inputs -->
          <base-input
            v-show="show_RoofFinishingRemovalPermission"
            type="radiobuttons" :options="yesNo"
            name="RoofFinishingRemovalPermission"
            v-model:value="lead.roof.finishing.RoofFinishingRemovalPermission" :validator="v$.roof.finishing.RoofFinishingRemovalPermission">
            De afwerking moet verwijderd worden om te kunnen isoleren. Gaat u hier mee akkoord?
          </base-input>
          <issue-feedback :issue="qualStore.sections.roof.rules.RoofFinishingRemovalPermission" />

          <!-- inputs -->
          <transition name="fade-in-up">
            <base-input
              v-show="show_RoofBeamCoveringRemoved"
              type="radiobuttons" :options="yesNo"
              name="RoofBeamCoveringRemoved"
              v-model:value="lead.roof.finishing.RoofBeamCoveringRemoved" :validator="v$.roof.finishing.RoofBeamCoveringRemoved">
              Gaat u zelf de afwerking over de balken demonteren?
            </base-input>
          </transition>
          <issue-feedback :issue="qualStore.sections.roof.rules.RoofBeamCoveringRemoved" />

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.roof.finishing.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue";
  import BaseCard from '@/components/BaseCard.vue';
  import BaseForm from "@/components/BaseForm.vue";
  import BaseInput from "@/components/BaseInput.vue";
  import BaseButton from "@/components/BaseButton.vue";
  import ButtonRow from "@/components/ButtonRow.vue";
  import IssueFeedback from "@/components/IssueFeedback.vue";

  import { useAllStores } from "@/helpers/UseAllStores";
  import { CommonComposable } from "@/helpers/CommonComposable";
  import { FlowService } from "@/services/FlowService";

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ]
      };
    },
    computed: {
      show_RoofInsideFinishingPresent() {
        return !this.lead.skipRoofFinishing && this.lead.AtticUsedForLiving;
      },
      show_RoofFinishingRemovalPermission() {
        if (!this.show_RoofInsideFinishingPresent) { return false; }
        return this.lead.roof.finishing.RoofInsideFinishingPresent === true;
      },
      show_RoofBeamCoveringRemoved() {
        if (!this.show_RoofInsideFinishingPresent) { return false; }
        return this.lead.roof.finishing.RoofInsideFinishingPresent === true;
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.roof.finishing.$touch();
        return !this.v$.roof.finishing.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>