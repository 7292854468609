<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow w-100">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Dakisolatie</h1>

      <!-- form 1 card -->
      <base-card class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="radiobuttons"
            :options="insulationStatusOptions"
            name="RoofInsulationStatus"
            v-model:value="lead.roof.general.RoofInsulationStatus"
            :validator="v$.roof.general.RoofInsulationStatus">
            Is het dak reeds ge&iuml;soleerd?
          </base-input>
          <issue-feedback :issue="qualStore.sections.roof.rules.RoofInsulationStatus" />

          <!-- inputs -->
          <base-input
            type="radiobuttons"
            :options="insulationSideOptions"
            name="RoofInsulationSide"
            v-model:value="lead.roof.general.RoofInsulationSide"
            :validator="v$.roof.general.RoofInsulationSide">
            Wilt u het dak van binnen of van buiten isoleren?
          </base-input>
          <issue-feedback :issue="qualStore.sections.roof.rules.RoofInsulationSide" />

          <!-- inputs -->
          <base-input
            type="radiobuttons"
            :options="roofTypeOptions"
            name="RoofType"
            v-model:value="lead.roof.general.RoofType"
            :validator="v$.roof.general.RoofType">
            Is uw dak schuin of plat?
          </base-input>
          <issue-feedback :issue="qualStore.sections.roof.rules.RoofType" />

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.roof.general.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
        <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue";
  import BaseCard from '@/components/BaseCard.vue';
  import BaseForm from "@/components/BaseForm.vue";
  import BaseInput from "@/components/BaseInput.vue";
  import BaseButton from "@/components/BaseButton.vue";
  import ButtonRow from "@/components/ButtonRow.vue";
  import IssueFeedback from "@/components/IssueFeedback.vue";

  import { useAllStores } from "@/helpers/UseAllStores";
  import { CommonComposable } from "@/helpers/CommonComposable";
  import { FlowService } from "@/services/FlowService";

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        insulationSideOptions: [
          {
            label: 'Binnen',
            value: 'inside'
          },
          {
            label: 'Buiten',
            value: 'outside'
          },
          {
            label: 'Geen voorkeur',
            value: 'unknown' // will be "inside"
          },
        ],
        insulationStatusOptions: [
          { label: 'Volledig', value: 'Volledig geisoleerd' },
          { label: 'Gedeeltelijk', value: 'Gedeeltelijk geisoleerd' },
          { label: 'Niet geïsoleerd', value: 'Geen isolatie' },
        ],
        roofTypeOptions: [
          { label: 'Schuin dak', value: 'Schuin dak' },
          { label: 'Plat dak', value: 'Plat dak' }
        ]
      };
    },
    methods: {
      touchAndValidate() {
        this.v$.roof.general.$touch();
        return !this.v$.roof.general.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>