<template>
  <interview-layout :show-nav="false">
    <div class="container-narrow d-flex flex-column align-items-center">
      <div class="mt-n5 position-relative">
        <h1 class="text-center fs-1-3em">Uw gegevens worden opgehaald...</h1>
      </div>
    </div>
  </interview-layout>
</template>

<script>
  import { LeadDataService } from "@/services/LeadDataService";
  import { CommonComposable } from "@/helpers/CommonComposable";
  import { FlowService } from "@/services/FlowService";
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"

  export default {
    components: {
      InterviewLayout
    },
    setup(props, context) {
      return CommonComposable(props, context);
    },
    mounted() {
      (async () => {
        await LeadDataService.fetchRemoteData(this.$route.params.uuid);
        let debug_doNotRedir = false; // REMOVE BEFORE FLIGHT
        if (!debug_doNotRedir) { // REMOVE BEFORE FLIGHT
          // if redir query param is set, redirect back to view
          if (this.$route.query.redir) {
            this.$router.replace({
              name: this.$route.query.redir,
              params: this.$route.params,
              query: this.StripQuery(this.$route.query, ['test'])
            });
          // else redirect to first incomplete section
          } else {
            this.$router.replace({
              name: FlowService.getFirstIncomplete(true),
              params: this.$route.params,
              query: this.StripQuery(this.$route.query, ['test'])
            });
          }
        } // REMOVE BEFORE FLIGHT
      })().catch(() => {
        // if not found or connection error, redirect to start with error message
        this.$router.replace({
          name: FlowService.getFirstIncomplete(true),
          query: {
            error_once: 'fetch-error'
          }
        });
      });
    }
  }
</script>