<template>
  <interview-layout>
    <!-- inner wrapper used for centering -->
    <div class="w-100 container-narrow">
      <!-- title above form card -->
      <h3 class="pb-3 fade-in-up-enter-active animation-slowest">Heeft u een relatiecode?</h3>
      <!-- animated answer buttons and input section -->
      <div class="answer-wrapper">
        <Transition name="fade-in-up" mode="out-in">
          <!-- answer buttons -->
          <div class="d-flex-soft" v-if="!uiStore.showPartnerCouponForm">
            <BaseButton variant="minimal-filled-primary" class="me-2" @click="onYes()">
              Ja, ik heb een code
            </BaseButton>
            <BaseButton variant="primary" icon="bi-arrow-right" @click="onNo()">
              Nee, ga verder
            </BaseButton>
          </div>
          <!-- coupon input row -->
          <div v-else>
            <BaseInput
              name="PartnerCoupon"
              :focusOnMounted="true"
              v-model:value="lead.meta.PartnerCoupon"
              :validator="v$.meta.PartnerCoupon"
              :debounce="700"
              :debounce-touch-only="true"
              :do-not-reward="true"
              :force-validation-state="validationState"
              :force-validation-message="validationMessage"
              @keyup.enter="checkCoupon"
            >
              Relatiecode
            </BaseInput>
            <div class="d-flex-soft">
              <BaseButton
                variant="primary"
                class="me-2"
                :icon="checkBtnIcon"
                @click="checkCoupon"
                :disabled="busy"
              >
                Gebruik code
              </BaseButton>
              <BaseButton
                variant="minimal-primary"
                @click="onNo()"
              >
                Overslaan
              </BaseButton>
            </div>
          </div>
        </Transition>
      </div>
      <button-row class="mt-5">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
    </div>
  </interview-layout>
</template>

<script>
import InterviewLayout from './layouts/InterviewLayout.vue'
import BaseButton from '@/components/BaseButton.vue'
import ButtonRow from '@/components/ButtonRow.vue'
import BaseInput from '@/components/BaseInput.vue'

import { useAllStores } from '@/helpers/UseAllStores'
import { useCouponStore } from '@/store/CouponStore'
import { FlowService } from "@/services/FlowService"
import { CouponDataService } from "@/services/CouponDataService"

export default {
  components: {
    InterviewLayout,
    ButtonRow,
    BaseButton,
    BaseInput
  },
  setup() {
    const couponStore = useCouponStore();
    return {
      ...useAllStores(),
      couponStore
    };
  },
  data() {
    return {
      busy: false
    }
  },
  computed: {
    checkBtnIcon() {
      return this.busy ? 'bi-gear-fill spin-infinite' : null;
    },
    validationState() {
      if (this.couponStore.valid) {
        return 'valid';
      } else if (this.couponStore.valid === false) {
        return 'invalid';
      } else {
        return 'neutral';
      }
    },
    validationMessage() {
      if (this.busy) {
        return null;
      } else if (this.validationState == 'invalid') {
        return 'Deze code is niet geldig.';
      } else if (this.validationState == 'valid') {
        return 'Deze code is geldig!';
      } else {
        return null;
      }
    }
  },
  methods: {
    checkCoupon() {
      this.busy = true;

      CouponDataService.verifyCoupon(this.lead.meta.PartnerCoupon)
      .then(() => {
        FlowService.pushNext(this, {
          'coupon_once': 'valid'
        });
      })
      .catch((error = true) => {
        console.log(error);
        this.couponStore.valid = false;
      })
      .then(() => {
        this.busy = false;
      })
    },

    onYes() {
      this.uiStore.showPartnerCouponForm = true;
    },
    onNo() {
      FlowService.pushNext(this);
    },
    onPrev() {
      FlowService.pushPrev(this);
    }
  }
}
</script>