import { computed } from "vue";
import { QualIssue } from '@/services/QualClasses';
import { useFormStores as useStores } from '@/rules/_useFormStores';

export const rules = {
  // check if enabled and complete
  enabledAndIncomplete: computed(() => {
    let { lead, validator } = useStores('glazing', true);
    // check if interest is checked
    if (!lead.interestsArray.includes('Glazing')) {
      return null;
    }
    // if checked, check if form is valid
    if (validator.$invalid) {
      return new QualIssue({
        view: 'formGlazing',
        title: 'Belangrijke informatie ontbreekt',
        description:
          'Niet alle vereiste informatie is ingevuld. Daarom kunnen wij (nog) niet bepalen of u in aanmerking komt voor HR++ glas.',
      });
    }
    return null;
  }),

  // monuments are disqualified
  IsMonument: computed(() => {
    let { form } = useStores('glazing');
    if (form.general.IsMonument == true) {
      return new QualIssue({
        view: 'formGlazing',
        title: 'Betreft een monument',
        description:
          'Bij panden met monumentale status kunnen wij geen beglazing vervangen.',
      });
    }
    return null;
  }),

  // building must accessible from all sides
  BuildingAccessibleAllround: computed(() => {
    let { form } = useStores('glazing');
    if (form.general.BuildingAccessibleAllround == false) {
      return new QualIssue({
        view: 'formGlazing',
        title: 'Pand niet goed bereikbaar',
        description:
          'Wij moeten alle te vervangen beGlazing goed kunnen bereiken.',
      });
    }
    return null;
  }),

  // when double Glazing is present, it must be older than 1995
  GlazingToReplace: computed(() => {
    let { lead } = useStores('glazing');
    if (lead.GlazingToReplace == 'dubbel-na-1995') {
      return new QualIssue({
        view: 'formGlazing',
        title: 'Modern dubbelglas al aanwezig',
        description:
          'U heeft aangegeven dat uw huidige dubbelglas van 1995 of later is. Nieuw dubbel glas aanbrengen levert te weinig besparing op.',
      });
    }
    return null;
  }),

  // warning when rebate width < 40mm
  RebateWidthSufficient: computed(() => {
    let { form } = useStores('glazing');
    if (form.frame.RebateWidthSufficient == false) {
      return new QualIssue({
        view: 'formGlazingFrame',
        type: 'warning',
        title: 'Extra kosten uitfrezen sponningen',
        description:
          'Bij een sponningbreedte van minder 40mm moeten wij de sponningen uitfrezen. Dit brengt extra kosten met zich mee.',
      });
    }
    return null;
  }),

  // frame material must be wood
  FrameMaterial: computed(() => {
    let { form } = useStores('glazing');
    let FrameMaterial = form.frame.FrameMaterial || 'ongeschikt materiaal';
    if (form.frame.FrameMaterial !== null && form.frame.FrameMaterial !== 'hout') {
      return new QualIssue({
        view: 'formGlazingFrame',
        title: 'De kozijnen zijn van ' + FrameMaterial,
        description: 'Wij vervangen alleen dubbel glas in houten kozijnen.',
      });
    }
    return null;
  }),

  // wooden frames in bad condition need replacing, showing warning for extra costs
  FrameCondition: computed(() => {
    let { form } = useStores('glazing');
    if (form.frame.FrameMaterial == 'hout' && form.frame.FrameCondition == 'bad') {
      return new QualIssue({
        view: 'formGlazingFrame',
        type: 'warning',
        title: 'Extra kosten voor herstellen houtwerk',
        description: 'Houtwerk in slechte conditie zal hersteld moeten worden. Dit brengt extra kosten met zich mee.',
      });
    }
    return null;
  }),

};
