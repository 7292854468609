import { useCouponStore } from '@/store/CouponStore';
import axios from 'axios';
import constants from '@/constants';
// import { touchAllWithValue } from '@/store/LeadValidator';

export var api = axios.create({
  baseURL: constants.baseURL,
});

export const CouponDataService = {

  verifyCoupon(PartnerCoupon = null) {
    return new Promise((resolve, reject) => {
      let couponStore = useCouponStore();
      // new api call
      api
        .get('/rest/coupon/' + PartnerCoupon)
        .then(function (response) {
          if (response.data && response.data.data && response.data.status == 'success') {
            // coupon found and valid
            couponStore.loadCouponData(response.data.data);
            resolve();
          } else {
            couponStore.loadCouponData(null);
            reject(false);
          }
        })
        .catch(function (error) {
          // connection error
          console.error(error);
          reject();
        });
    });
  },

};
