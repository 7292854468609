import { createRouter, createWebHistory } from 'vue-router'
import { useInterfaceStore } from '@/store/UiStore';
import { FlowGuard, FlowService } from '@/services/FlowService';
import { QualGuard } from '@/services/QualGuard';
import { StripQuery } from '@/helpers/StripQuery';

import PassThrough from '@/views/PassThrough.vue';
import FetcherView from '@/views/FetcherView.vue';
import FormContactView from '@/views/FormContactView.vue';
import { LeadDataService } from '@/services/LeadDataService';
import { debounce } from 'lodash';
import { getInterestDefinition } from '@/rules/InterestDefinitions';
import { useLeadStore } from '@/store/LeadStore';

const routes = [
  {
    path: '/:uuid?',
    name: 'home',
    component: PassThrough,
    redirect: () => {
      return { name: 'formContact' };
    },

    children: [
      {
        path: 'fetch',
        name: 'fetcher',
        component: FetcherView,
      },
      {
        path: 'contact-details',
        name: 'formContact',
        component: FormContactView,
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'address-details',
        name: 'formAddress',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormAddressView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'partner-coupon',
        name: 'formPartnerCoupon',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormPartnerCouponView.vue'
          ),
        beforeEnter: [
          QualGuard,
          FlowGuard,
          (to) => {
            let store = useInterfaceStore();
            if (store.skipFormPartnerCoupon) {
              return { name: FlowService.getNext(to.name), params: to.params };
            }
          },
        ],
      },
      {
        path: 'ownership',
        name: 'formOwnership',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormOwnershipView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'property',
        name: 'formProperty',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormPropertyView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'interests',
        name: 'formInterests',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormInterestsView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'cavity-wall',
        name: 'formCavityWall',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormCavityWallView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'cavity-wall-paint',
        name: 'formCavityWallPaint',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormCavityWallPaintView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'cavity-wall-stucco',
        name: 'formCavityWallStucco',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormCavityWallStuccoView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'floor',
        name: 'formFloor',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormFloorView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'floor-details',
        name: 'formFloorDetails',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormFloorDetailsView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'roof',
        name: 'formRoof',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormRoofView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'roof-attic',
        name: 'formRoofAttic',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormRoofAtticView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'roof-finishing',
        name: 'formRoofFinishing',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormRoofFinishingView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'glazing',
        name: 'formGlazing',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormGlazingView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'glazing-frame',
        name: 'formGlazingFrame',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormGlazingFrameView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'glazing-dimensions',
        name: 'formGlazingDimensions',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormGlazingDimensions.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'heatpump',
        name: 'formHeatpump',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormHeatpumpView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'solar',
        name: 'formSolar',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/FormSolarView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'blocking-issue',
        name: 'blockingGlobalIssue',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/QualificationGlobalView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
      {
        path: 'result',
        name: 'qualificationResult',
        component: () =>
          import(
            /* webpackChunkName: "formViews" */ '../views/QualificationView.vue'
          ),
        beforeEnter: [QualGuard, FlowGuard],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  let uiStore = useInterfaceStore();
  let lead = useLeadStore();
  let fetcherViewName = 'fetcher';
  // set route on uiStore
  uiStore.currentRoute = to;
  // if url contains partner coupon, set on store
  if (to.query.coupon && !uiStore.skipFormPartnerCoupon) {
    uiStore.setPartnerCouponOnLoad(to.query.coupon);
    to.query.coupon = null;
  }
  // if url contains internal user ID, set on stores
  if (to.query.sf_user_id) {
    uiStore.setInternalUserIdOnLoad(to.query.sf_user_id);
  }
  // if url contains load_dummy_data, run fetchDummyData
  if (to.query.load_dummy_data) {
    LeadDataService.fetchDummyData();
    delete to.query.load_dummy_data;
  }
  // if data was not already fetched and path contains uuid (and it is not the fetcher view, to avoid infinite redirect loop)
  if (!uiStore.fetchedFromSf && !uiStore.fetchedFromSession && to.params.uuid && to.name !== fetcherViewName) {
    uiStore.setUuid(to.params.uuid);
    // redirect to loading page
    return {
      name: fetcherViewName,
      params: to.params,
      query: {...to.query, ...{redir: to.name}}
    };
  }

  // else load query params for lead
  // lead source param
  if (to.query.src) {
    lead.setLeadSource(to.query.src);
    if (to.name !== fetcherViewName) {
      delete to.query.src;
    }
  }
  // show hidden interests param
  if (to.query.all_intr) {
    lead.meta.AllInterestsEnabled = to.query.all_intr !== 'false';
    if (to.name !== fetcherViewName) {
      delete to.query.all_intr;
    }
  }

  // disableCouponForm
  if (to.query.disable_coupon) {
    uiStore.disableCouponForm = to.query.disable_coupon !== 'false';
    if (to.name !== fetcherViewName) {
      delete to.query.disable_coupon;
    }
  }

  // if skip_contact_details
  if (to.query.skip_contact_details && to.query.skip_contact_details !== 'false') {
    return {
      name: 'formInterests',
      params: to.params,
      query: {...StripQuery(to.query, 'skip_contact_details')}
    };
  }
  // if specific interest, ensure interest is checked and redirect
  if (to.query.check_interest && to.name !== fetcherViewName) {
    let interestDefinition = getInterestDefinition(to.query.check_interest, true);
    if (interestDefinition) {
      const lead = useLeadStore();
      lead.checkInterest(interestDefinition.value);
      return {
        name: interestDefinition.viewName,
        params: to.params,
        query: {...StripQuery(to.query, 'check_interest')}
      };
    }
  }
});

router.afterEach(debounce( () => {
  let uiStore = useInterfaceStore();
  (async () => {
    LeadDataService.pushRemoteData(uiStore.uuid);
  })().catch(() => {
    console.log('Push cancelled.');
  });
}, 4000, {
  'leading': true,
  'trailing': true,
}));

export default router
