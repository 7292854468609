<template>
  <div class="toast-overlay" v-show="toasts.length > 0">
   <template v-for="toast in toasts" :key="toast.key">
    <toast-alert
      :title="toast.title"
      :subtitle="toast.subtitle"
      :body="toast.body"
      :variant="toast.variant"
    />
   </template>
  </div>
</template>

<script>
import ToastAlert from "./ToastAlert.vue";
import ArrayLength from '@/helpers/ArrayLength';
import { useAllStores } from "@/helpers/UseAllStores";
import { get } from 'lodash';

export default {
  setup() {
    return useAllStores();
  },
  components: {
    ToastAlert
  },
  computed: {
    toasts() {
      let toasts = [];
      if (this.$route.query.error_once == 'fetch-error') {
        toasts.push({
          key: 'error-fetch-error',
          title: 'Gegevens niet vooringevuld',
          subtitle: 'zojuist',
          body: 'Wij konden uw gegevens helaas niet ophalen.',
          variant: 'highlight'
        });
      }
      if (this.$route.query.coupon_once == 'valid') {
        let couponTypeName = get(this.uiStore, 'PartnerCoupon.coupon_type', 'Code');
        toasts.push({
          key: 'coupon-valid',
          title: couponTypeName+' toegepast!',
          subtitle: 'zojuist',
          body: 'Uw ' + couponTypeName.toLowerCase() + ' is succesvol toegepast!',
          variant: 'success'
        });
      }
      return toasts;
    },
    hasToast() {
      return ArrayLength(this.toasts) > 0;
    }
  }
}
</script>

<style lang="scss">
  .toast-overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: unset;

    display: flex;
    justify-content: center;
  }
</style>