import { useQualificationStore } from "@/store/QualificationStore";
import { FlowService } from "./FlowService";

export function QualGuard(to, from) {
  const blockingIssueViewName = 'blockingGlobalIssue';
  // if from route is blocking issue view, skip this guard
  if (from.name == blockingIssueViewName) { return true; }
  // if not, we will need the qualificationStore
  let qualStore = useQualificationStore();
  // if to route is blocking issue view, only check if there really are issues
  if (to.name == blockingIssueViewName) {
    // if indeed disqualified, continue navigation
    if (qualStore.global.status == 'disqualified') {
      return true;
    }
    // if not disqualified, go to next view in sequence
    return {
      name: FlowService.getNext(),
      params: to.params,
    };
  }
  // else chekc if there are blocking issues
  if (qualStore.global.status == 'disqualified') {
    console.log('Detected blocking issue. Redirecting...')
    return {
      name: blockingIssueViewName,
      params: to.params,
    };
  }
}