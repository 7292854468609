import useVuelidate from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import { useLeadStore } from '@/store/LeadStore';
import { inject, ref, computed } from 'vue';

import { requireIfField, requireIfFields } from '@/helpers/ValidatorRequireIfField';
import { array } from '@/helpers/ValidatorArray';
import { get } from 'lodash';
import { GetEndPaths } from '@/helpers/GetEndPaths';

export const injectGlobalVuelidate = () => {
    return inject('leadv$')
}

export const useGlobalVuelidate = () => {
    return globalVuelidateService.ref
}

export const globalVuelidateService = {
    initialized: false,
    state: null,
    v$: null,
    rules: computed(() => {
        // let state = globalVuelidateService.state;
        return {
          meta: {
            PartnerCoupon: { required },
          },
          interests: {
            Interests: {
              required,
              minLength: minLength(1),
              array,
            },
          },
          contact: {
            FirstName: { required },
            LastName: { required },
            Email: { required, email },
            Phone: {
              phone: (value) => {
                value = String(value).replaceAll(' ', ''); // remove whitespace
                value = String(value).replaceAll('-', ''); // remove dashes
                return value.length >= 8 // at least 8 chars long
                  && (value.match(/\d/g) || []).length > 7; // at least 8 numbers
              }
            },
          },
          address: {
            Street: { required },
            StreetNumber: { required },
            PostalCode: { 
              zipCode: (value) => {
                value = String(value).replaceAll(' ', ''); // remove whitespace
                return value.length == 6 // 6 chars
                  && (value.match(/\d/g) || []).length == 4; // 4 numbers
              }
             },
            City: { required },
          },
          ownership: {
            PropertyOwner: {
              required,
            },
            HoaMember: { required },
            HoaPermission: {
              required: requireIfField('HoaMember', true),
            },
            HoaRepName: {
              required: requireIfFields({
                HoaMember: true,
                HoaPermission: true,
              }),
            },
            HoaRepPhone: {
              required: requireIfFields({
                HoaMember: true,
                HoaPermission: true,
              }),
            },
            HoaRepEmail: {
              required: requireIfFields({
                HoaMember: true,
                HoaPermission: true,
              }),
              email,
            },
          },
          property: {
            ConstructionYear: { required },
            GasConsumption: {},
            PowerConsumption: {},
            HouseType: { required },
          },
          cavity_wall: {
            general: {
              CavityWallPresent: { required },
              FacadeMaterial: { required },
            },
            paint: {
              WallsPainted: { required },
              AcceptWallRepaint: {
                require: requireIfField('WallsPainted', true),
              },
            },
            stucco: {
              WallsPlastered: { required },
              AcceptStuccoRepaint: {
                require: requireIfField('WallsPlastered', true),
              },
            },
          },
          floor: {
            general: {
              SpaceBeneathFloor: { required },
              CrawlSpaceAccessible: { required },
            },
            details: {
              CrawlSpaceHeightCm: { required },
              FloorInsulationSide: { required },
            },
          },
          roof: {
            general: {
              RoofInsulationStatus: { required },
              RoofInsulationSide: { required },
              RoofType: { required },
            },
            attic: {
              AtticPresent: { required },
              AccessToRoofInside: { required },
              AtticUsage: {
                // required if skipRoofFinishing = false
                require: (value) => {
                  let lead = useLeadStore();
                  return !lead.skipRoofFinishing ? helpers.req(value) : true;
                },
              },
            },
            finishing: {
              RoofBeamsVisible: {
                // required if skipRoofFinishing = false
                require: (value) => {
                  let lead = useLeadStore();
                  return !lead.skipRoofFinishing ? helpers.req(value) : true;
                },
              },
              RoofInsideFinishingPresent: {
                // required if skipRoofFinishing = false AND attic is used for living
                require: (value) => {
                  let lead = useLeadStore();
                  return !lead.skipRoofFinishing && lead.AtticUsedForLiving
                    ? helpers.req(value)
                    : true; // not required = always valid
                },
              },
              RoofFinishingRemovalPermission: {
                // required if skipRoofFinishing = false AND attic is used for living
                require: (value, form) => {
                  let lead = useLeadStore();
                  return !lead.skipRoofFinishing && lead.AtticUsedForLiving && form.RoofInsideFinishingPresent == true
                    ? helpers.req(value)
                    : true; // not required = always valid
                },
              },
              RoofBeamCoveringRemoved: {
                // required if skipRoofFinishing = false AND attic is used for living AND roof finishing is present
                require: (value, form) => {
                  let lead = useLeadStore();
                  return !lead.skipRoofFinishing &&
                    lead.AtticUsedForLiving &&
                    form.RoofInsideFinishingPresent == true
                    ? helpers.req(value)
                    : true; // not required = always valid
                },
              },
            },
          },
          glazing: {
            general: {
              IsMonument: { required },
              BuildingAccessibleAllround: { required },
              CurrentGlazingType: { required },
              CurrentGlazingAge: { required }
            },
            frame: {
              FrameMaterial: { required },
              FrameCondition: {
                require: requireIfField('FrameMaterial', 'Hout')
              },
              RebateWidthSufficient: { required }
            },
            dimensions: {
              // Panes is not required
            },
          },
          heatpump: {
            HeatingSystem: { required },
            HeatpumpFinancing: { required }
          },
          solar: {
            PlacementLocations: {
              required,
              minLength: minLength(1),
              array,
            },
          }
        };
    }),
    init() {
        this.state = useLeadStore();
        this.v$ = useVuelidate(this.rules, this.state);
        this.ref = ref(this.v$)
    }
};

export let globalVuelidatePlugin = {
  install: (app) => {
    globalVuelidateService.init();
    app.provide('leadv$', ref(globalVuelidateService.v$));
  },
};

export const touchAllWithValue = function()
{
  let lead = useLeadStore();
  let v$ = globalVuelidateService.ref;
  let paths = GetEndPaths(lead.$fields);
  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];
    let value = get(lead, path, null);
    if (value !== null) {
      let fv$ = get(v$.value, path, null);
      if (fv$ !== null) {
        fv$.$touch();
      }
    }
  }
};
