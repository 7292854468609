import { isObject, merge } from "lodash";
import { defineStore } from "pinia";

export const useCouponStore = defineStore({
  id: 'couponStore',
  state: () => {
    return {
      coupon_code: null,
      valid: null,
      active: null,
      coupon_type: null,
      display_name: null,
      checked_at: null
    }
  },
  actions: {
    loadCouponData(couponData = null) {
      this.$reset();
      if (couponData && isObject(couponData)) {
        merge(this.$state, couponData);
      }
    },
    couponRecheckRequired(coupon_code = null) {
      if (!coupon_code) { return true; }
      // if new coupon code, recheck
      if (coupon_code !== this.coupon_code) {
        return true;
      // else if checked more than 24 hours ago
      } else if (this.checked_at < (Date.now() - 86400000)) {
        console.log('Coupon code last checked more than 24 hours ago. Verifying again.');
        return true;
      }
      return false;
    }
  }
});