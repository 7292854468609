import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import { QualSection } from '@/services/QualClasses';
import { enabledMeasures } from '@/helpers/Measures';

import { rules as rules_global } from '@/rules/GlobalRules';
import { rules as rules_cavity_wall } from '@/rules/CavityWallRules';
import { rules as rules_roof } from '@/rules/RoofRules';
import { rules as rules_floor } from '@/rules/FloorRules';
import { rules as rules_glazing } from '@/rules/GlazingRules';
import { rules as rules_heatpump } from '@/rules/HeatpumpRules';
import { rules as rules_solar } from '@/rules/SolarRules';
import { useInterfaceStore } from './UiStore';
import { isObject } from 'lodash';


/**
 * QualificationStore
 * responsible for qualification logic spanning multiple measures
 */
export const useQualificationStore = defineStore({
  id: 'QualificationStore',
  state: () =>
    reactive({
      // rules that block qualification completely
      global: new QualSection({
        key: 'global',
        rules: reactive(rules_global),
      }),
      // sections that can be qualified individually
      sections: {
        cavity_wall: new QualSection({
          key: 'cavity_wall',
          rules: reactive(rules_cavity_wall),
        }),
        roof: new QualSection({
          key: 'roof',
          rules: reactive(rules_roof),
        }),
        floor: new QualSection({
          key: 'floor',
          rules: reactive(rules_floor),
        }),
        glazing: new QualSection({
          key: 'glazing',
          rules: reactive(rules_glazing),
        }),
        heatpump: new QualSection({
          key: 'heatpump',
          rules: reactive(rules_heatpump),
        }),
        solar: new QualSection({
          key: 'solar',
          rules: reactive(rules_solar),
        }),
      },
    }),
});

export const enabledSections = computed(() => {
  let qualStore = useQualificationStore();
  let enabledSections = {};
  Object.values(enabledMeasures.value).forEach((measure) => {
    if (measure.key in qualStore.sections) {
      enabledSections[measure.key] = qualStore.sections[measure.key];
    }
  });
  return enabledSections;
});

export const enabledSectionsComplete = computed(() => {
  let uiStore = useInterfaceStore();
  if (uiStore.generalFormsComplete == false) {
    return false;
  }
  let sections = enabledSections.value;
  for (const key in sections) {
    const section = sections[key];
    if (isObject(section) && 'enabledAndIncomplete' in section) {
      if (section.rules.enabledAndIncomplete !== null) {
        return false;
      }
    }
  }
  return true;
});

export const qualifiedSectionKeys = computed(() => {
  let uiStore = useInterfaceStore();
  if (uiStore.generalFormsComplete == false) {
    return [];
  }
  let allSections = enabledSections.value;
  let qualifiedSectionKeys = [];
  for (const key in allSections) {
    const section = allSections[key];
    if (isObject(section) && section.qualified) {
      qualifiedSectionKeys.push(section.key);
    }
  }
  return qualifiedSectionKeys;
});