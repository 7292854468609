<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow w-100">
      <!-- title above form card -->
      <h1 class="px-4 max-100">Spouwmuurisolatie</h1>
      <h6 class="px-4 pb-3 max-100 text-primary fade-in-up-enter-active animation-slowest delay-200">Geverfde buitenmuren</h6>

      <!-- form 1 card -->
      <base-card class="w-100" inner-class="container-narrow" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="radiobuttons" :options="yesNo"
            name="WallsPainted"
            v-model:value="lead.cavity_wall.paint.WallsPainted" :validator="v$.cavity_wall.paint.WallsPainted">
            Zijn de buitenmuren geverfd?
          </base-input>
          <issue-feedback :issue="qualStore.sections.cavity_wall.rules.WallsPainted" />

          <transition name="fade-in-up">
            <base-input
              v-show="lead.cavity_wall.paint.WallsPainted"
              type="radiobuttons" :options="acceptDamageOptions"
              name="AcceptWallRepaint"
              v-model:value="lead.cavity_wall.paint.AcceptWallRepaint" :validator="v$.cavity_wall.paint.AcceptWallRepaint">
              Accepteert u dat er een groot risico bestaat op schade en dat er <strong class="text-danger">geen</strong> garantie gegeven zal worden op het resultaat?
            </base-input>
          </transition>
          <issue-feedback :issue="qualStore.sections.cavity_wall.rules.AcceptWallRepaint" />

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.cavity_wall.paint.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        acceptDamageOptions: [
          {
            label: 'Ja, ik accepteer het risico',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        facadeMaterialOptions: [
          { label: 'Stenen muur met spouw', value: 'Steen' },
          { label: 'Houtskelet', value: 'Houtskelet' },
          { label: 'Hout', value: 'Hout' },
          { label: 'Enkelsteensmuur', value: 'Enkelsteensmuur' },
          { label: 'Deels hout', value: 'Deels hout' },
          { label: 'Deels kunststof', value: 'Deels kunststof' },
          { label: 'Deels steen', value: 'Deels steen' }
        ]
      };
    },
    methods: {
      touchAndValidate() {
        this.v$.cavity_wall.paint.$touch();
        return !this.v$.cavity_wall.paint.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>